import * as React from 'react';
import { PreviewStoreProvider } from 'gatsby-source-prismic';
import 'focus-visible/dist/focus-visible.js';


export const wrapRootElement = ({ element }) => (
	<PreviewStoreProvider>{element}</PreviewStoreProvider>
)

async function loadPolyfills() {
	if (typeof window.IntersectionObserver === 'undefined') {
    	await require('intersection-observer');
	}
	  
	if('objectFit' in document.documentElement.style === false) {
		const objectFitImages = await require('object-fit-images');
		objectFitImages();
	}
}

loadPolyfills();